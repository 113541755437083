import React from 'react';
import Header from '../../components/remote-work-resources/Header';
import Footer from '../../components/remote-work-resources/Footer';
import { Helmet } from 'react-helmet';

export default function BackToWorkForm() {
    return (
        <div className='next-opportunity'>
            <Helmet>
                <title>Get added to The Back-to-Work Project</title>
            </Helmet>
            <div className='next-opportunity-container'>
                <Header />
                <div className='next-opportunity-typeform-full'>
                    <div className='next-opportunity-typeform-full-header'>
                        <h3>
                            Get added to The Back-to-Work Project and showcase your profile to Recruiters, Employers and
                            Hiring Managers
                        </h3>
                    </div>
                    <iframe
                        title={'typeform'}
                        id='typeform-full'
                        width='100%'
                        height='100%'
                        frameBorder='0'
                        src='https://springrole.typeform.com/to/yWdtJO'
                    />
                </div>
            </div>
            <Footer />
        </div>
    );
}
